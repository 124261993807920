import React from "react";
import { login } from "store/auth";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .title {
    font-family: var(--font-secondary);
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 150%;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.orange};
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 60%;

    @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
      flex-direction: row;
    }

    .column {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 50px;

      @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
        margin-bottom: initial;
      }

      p {
        font-family: var(--font-primary);
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        line-height: 140%;

        @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
          font-size: 21px;
          text-align: left;
        }
      }
    }
  }
`;

const PrimaryButton = styled.button`
  ${(props) => props.theme.buttons.primaryButton}
  line-height: 21px;
  height: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    height: 30px;
  }
`;

export default function LoginConcept() {
  const navigate = useNavigate();
  const handleClick = () => login();
  return (
    <Container>
      <div className="title-container">
        <p className="title">Login</p>
      </div>

      <div className="content">
        <div className="column">
          <p>Already a member?</p>
          <PrimaryButton onClick={handleClick}>Login</PrimaryButton>
        </div>
        <div className="column">
          <p>Not a member yet?</p>
          <PrimaryButton onClick={() => navigate("/signup")}>
            Create an account!
          </PrimaryButton>
        </div>
      </div>
    </Container>
  );
}
