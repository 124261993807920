import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth, checkLoggedIn } from "store/auth";

/**
 *
 * @param {object} props
 * @param {React.Component} props.component
 * @param {boolean} props.loggedIn
 */
export default function PrivateRoute({ children }) {
  const { state } = useAuth();
  const location = useLocation();
  const loggedIn = checkLoggedIn(state);

  return loggedIn ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}
