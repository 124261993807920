import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { DesktopOnly, TabletOnly, MobileOnly } from "components";
import { login } from "../../store/auth";
import headerImage from "../../assets/header.png";
import mobileHeaderImage from "../../assets/mobile-header.png";

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const HeaderStripe = styled.div`
  height: 230px;
  background-color: ${(props) => props.theme.colors.orange};
  position: relative;
  top: 137px;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    height: 200px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    height: 255px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    height: 285px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  top: -170px;
  margin-bottom: -220px;
  left: 0;
  right: 0;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  max-width: ${(props) => props.theme.breakpoints.mobile};
  border-bottom: 1px dashed ${(props) => props.theme.colors.orange};

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    top: -160px;
    margin-bottom: -160px;
    max-width: ${(props) => props.theme.breakpoints.small};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    top: -255px;
    margin-bottom: -255px;
    max-width: ${(props) => props.theme.breakpoints.medium};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    top: -280px;
    margin-bottom: -280px;
    max-width: ${(props) => props.theme.dimensions.maxWidth};
  }
`;

const LoginSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 0;
  border: none;
  background-color: transparent;
  padding: 10px;
  z-index: 1;
  min-width: 150px;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    top: 10px;
    right: 5px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  top: 0px;
  height: 100%;
  width: 100%;
  max-width: 280px;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    max-width: ${(props) => props.theme.breakpoints.small};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    max-width: ${(props) => props.theme.breakpoints.medium};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    max-width: ${(props) => props.theme.dimensions.maxWidth};
  }
`;

const HeaderImage = styled.img`
  width: 100%;
  height: auto;
  vertical-align: middle;
`;

const LoyaltyInfo = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  max-width: 936px;

  & p.title {
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 600;
    font-size: 40px;
    line-height: 150%;
    font-family: var(--font-secondary);
    color: ${(props) => props.theme.colors.orange};
    text-transform: uppercase;
    text-align: center;

    @media (min-width: ${(props) => props.theme.breakpoints.small}) {
      font-size: 54px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .info {
    display: flex;
    flex-direction: column;

    @media (min-width: ${(props) => props.theme.breakpoints.small}) {
      flex-direction: row;
    }
  }

  & p.description {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 17px;
    line-height: 130%;
    font-style: italic;
    font-family: var(--font-secondary);
    max-width: 600px;
    text-align: left;
    padding: 0 20px 0 20px;

    @media (min-width: ${(props) => props.theme.breakpoints.small}) {
      text-align: left;
      padding: initial;
    }
  }

  div.sub-title {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    order: 1;

    @media (min-width: ${(props) => props.theme.breakpoints.small}) {
      order: 0;
    }
  }
  p.sub-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    font-family: var(--font-secondary);
    text-align: center;
    text-transform: uppercase;
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 50px;
  }
`;

const PrimaryButton = styled.button`
  ${(props) => props.theme.buttons.primaryButton}
`;

const AnonymousHeader = () => {
  const navigate = useNavigate();
  return (
    <Section>
      <LoginSection>
        <PrimaryButton onClick={() => login()}>Login</PrimaryButton>
      </LoginSection>
      <HeaderStripe />
      <HeaderContent>
        <MobileOnly>
          <ImageWrapper>
            <HeaderImage src={mobileHeaderImage} />
          </ImageWrapper>
        </MobileOnly>
        <TabletOnly>
          <ImageWrapper>
            <HeaderImage src={headerImage} />
          </ImageWrapper>
        </TabletOnly>
        <DesktopOnly>
          <ImageWrapper>
            <HeaderImage src={headerImage} />
          </ImageWrapper>
        </DesktopOnly>
        <LoyaltyInfo>
          <p className="title">Join the Go Car Wash Loyalty Program</p>
          <div className="info">
            <p className="description">
              Take actions at GO Car Wash, earn loyalty points. Then, turn your
              points into rewards. <strong>It’s as easy as 1, 2, 3, GO!</strong>
            </p>
            <div className="sub-title">
              <p className="sub-title">Loyalty Members Get More</p>
            </div>

            <p className="description">
              Loyalty members earn points from actions they’re already taking,
              like washing regularly! Wash smarter and earn instant points when
              you become a GO Car Wash loyalty member.
            </p>
          </div>
          <div className="button-container">
            <PrimaryButton onClick={() => navigate("/signup")}>
              Become a Member
            </PrimaryButton>
          </div>
        </LoyaltyInfo>
      </HeaderContent>
    </Section>
  );
};

export default AnonymousHeader;
