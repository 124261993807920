import React, { useContext, useEffect, useState } from "react";
import { useApi } from "../hooks";
import { useMe } from "./me";

const LocalContext = React.createContext();
export const useTransactions = () => useContext(LocalContext);
export const TransactionsProvider = ({ children }) => {
  const { authenticatedFetch } = useApi();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  const { data: me } = useMe();

  useEffect(() => {
    (async () => {
      const customerId = me.customer.id;
      const endpoint = `transactions?customer=${customerId}`;
      const newData = await authenticatedFetch(endpoint);
      setData(newData);
      setLoading(false);
    })();
  }, [me, authenticatedFetch]);

  return (
    <LocalContext.Provider value={{ data, loading }}>
      {children}
    </LocalContext.Provider>
  );
};

export const TransactionsGate = ({ children }) => {
  const { data, loading } = useTransactions();

  if (loading || !data) return null;

  return children;
};
