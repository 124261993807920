import { useState } from "react";
import styled from "styled-components";
import { useTransactions } from "../../store/transactions";
import format from "date-fns/format";
import { Modal, BarcodeRedeem } from "../../components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    padding-left: 40px;
  }

  & .title {
    margin-block-start: 80px;
    font-size: 23px;
    font-family: var(--font-secondary);
    text-transform: uppercase;
  }

  & .table-container {
    height: 100%;
    max-height: 350px;
    background-color: ${(props) => props.theme.colors.lightGray};
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: scroll;
  }

  & table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 10px;

    & td {
      padding: 15px;
    }

    & thead {
      height: 45px;
      background-color: ${(props) => props.theme.colors.orange};
      font-size: 18px;
      font-family: var(--font-secondary);
      text-transform: uppercase;
      color: white;
    }

    & .table-row {
      font-size: 18px;
      font-family: var(--font-primary);
      line-height: 30px;
      border-bottom: 1px dashed ${(props) => props.theme.colors.gray};
    }
  }
`;

const BarcodeButton = styled.button`
  margin-top: 10px;
  ${(props) => props.theme.buttons.primaryButton}
  font-size: 12px;
`;

const PointsHistory = () => {
  const { data: transactions } = useTransactions();

  const [showModal, setShowModal] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState(false);

  const viewBarcode = function (transaction) {
    setCurrentTransaction(transaction);
    setShowModal(true);
  };

  return (
    <>
      <Container>
        <div className="title-container">
          <p className="title">Points History</p>
        </div>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <td>Date</td>
                <td>Event</td>
                <td>Details</td>
                <td>PTS</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {transactions
                .sort((a, b) =>
                  a.transaction_time < b.transaction_time ? 1 : -1
                )
                .map((transaction) => (
                  <tr className="table-row" key={transaction.id}>
                    <td>
                      {format(new Date(transaction.transaction_time), "M/d/y")}
                    </td>
                    <td>{transaction.event.name}</td>
                    <td>{transaction.event.description}</td>
                    <td>{transaction.point_value}</td>
                    <td>
                      {transaction.reward && (
                        <BarcodeButton onClick={() => viewBarcode(transaction)}>
                          View barcode
                        </BarcodeButton>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Container>

      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <BarcodeRedeem
          event={currentTransaction.event}
          reward={currentTransaction.reward}
          closeModal={() => setShowModal(false)}
        />
      </Modal>
    </>
  );
};

export default PointsHistory;
