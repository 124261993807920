import { useState } from "react";
import {
  NavLink,
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import styled from "styled-components";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { LinkFormContents } from "components";
import useApi from "../../hooks/useApi";
import { cleanPhoneNumber, checkStatus, cleanLicensePlate } from "utils";

const SignupContainer = styled.section`
  display: flex;
  flex: 1;
  align-self: center;
  flex-direction: column;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .input-row {
    display: flex;
    flex-direction: column;
  }

  label {
    font-family: var(--font-primary);
    font-weight: 600;
    font-size: 18px;
    line-height: 200%;
    letter-spacing: -0.01em;
  }

  input {
    height: 36px;
    width: 292px;
    padding-left: 10px;
  }

  .error {
    font-family: var(--font-primary);
    color: red;
  }

  .button-container {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
  }
`;

const PrimaryButton = styled.button`
  ${(props) => props.theme.buttons.primaryButton}
`;

const SignupFlowTab = styled(NavLink)`
  ${(props) => props.theme.buttons.signupFlowTab}
`;

const TabsStyles = styled.div`
  margin-top: 20px;
  margin-bottom: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: initial;
    width: 80%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    width: 65%;
  }

  .line {
    display: none;

    @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
      display: block;
      height: 5px;
      width: 75%;
      background-color: ${(props) => props.theme.colors.tabGray};
      margin: 0 10px 0 10px;
      z-index: -1;
      position: absolute;
      top: 45%;
    }
  }
`;

const EmailFlowStyles = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-family: var(--font-primary);
    font-size: 17px;
    line-height: 120%;
    max-width: 400px;
  }

  button {
    margin-top: 20px;
  }
`;

const Tabs = () => {
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <TabsStyles>
      <div className="line" />
      <SignupFlowTab onClick={handleClick} to="/signup/profile">
        Sign Up
      </SignupFlowTab>
      <SignupFlowTab onClick={handleClick} to="/signup/link">
        Link your Account
      </SignupFlowTab>
      <SignupFlowTab onClick={handleClick} to="/signup/email">
        Check your Email to Log In
      </SignupFlowTab>
    </TabsStyles>
  );
};

const ProfileForm = () => {
  const navigate = useNavigate();

  const handleSubmit = async (profile) => {
    profile.phone = cleanPhoneNumber(profile.phone);

    navigate("/signup/link", { state: profile });
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phone: Yup.string()
      .phone("US", false, "Phone number must be a valid US number")
      .required("Required"),
  });

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, values }) => {
        let isValidated = true;
        if (
          errors.firstName ||
          errors.lastName ||
          errors.email ||
          errors.phone ||
          values.firstName === "" ||
          values.lastName === "" ||
          values.emailName === "" ||
          values.phone === ""
        ) {
          isValidated = false;
        }

        const isDisabled = isSubmitting || !isValidated;

        return (
          <Form>
            <FormContainer>
              <div className="input-row">
                <label htmlFor="firstName">First name</label>
                <Field type="text" name="firstName" />
                <ErrorMessage
                  className="error"
                  name="firstName"
                  component="div"
                />

                <label htmlFor="lastName">Last name</label>
                <Field type="text" name="lastName" />
                <ErrorMessage
                  className="error"
                  name="lastName"
                  component="div"
                />

                <label htmlFor="email">Email address</label>
                <Field type="email" name="email" />
                <ErrorMessage className="error" name="email" component="div" />

                <label htmlFor="phone">Phone number</label>
                <Field type="tel" name="phone" />
                <ErrorMessage className="error" name="phone" component="div" />
                <div className="button-container">
                  <PrimaryButton type="submit" disabled={isDisabled}>
                    Next
                  </PrimaryButton>
                </div>
              </div>
            </FormContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

const SkipFlow = () => {
  const navigate = useNavigate();
  const { baseApiUrl } = useApi();
  const { state: profile } = useLocation();
  console.assert(profile, "profile is required");

  const handleSubmit = async () => {
    // no sitewatch_customer_id
    await fetch(`${baseApiUrl}/cognito`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        first_name: profile.firstName,
        last_name: profile.lastName,
        email: profile.email,
        phone: profile.phone,
      }),
    });

    navigate("/signup/email");
  };

  return (
    <EmailFlowStyles>
      <p>
        You’ve chosen to skip connecting your account. Here are the results of
        that choice.... Lorem ipsum dolor sit amet, consectetuer adipiscing
        elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut
        turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a,
        pede.
      </p>

      <PrimaryButton
        onClick={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        Next
      </PrimaryButton>
    </EmailFlowStyles>
  );
};

const LinkForm = ({ setShowModal }) => {
  const [error, setError] = useState(undefined);
  const navigate = useNavigate();
  const { state: profile } = useLocation();
  console.assert(profile, "profile is required");

  const { baseApiUrl } = useApi();

  const handleSubmit = async (values) => {
    // strip out spaces and capitalize
    const cleanLP = cleanLicensePlate(values.licensePlate);
    const siteWatchCode = `${cleanLP}-${values.state}`;
    setError(undefined);
    try {
      const res = await fetch(`${baseApiUrl}/cognito`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: profile.firstName,
          last_name: profile.lastName,
          email: profile.email,
          phone: profile.phone,
          customer_code: siteWatchCode,
        }),
      });

      // should throw a custom error/error on bad responses
      checkStatus(res);

      navigate("/signup/email");
    } catch (e) {
      setError(e);
    }
  };

  if (profile) {
    if (profile.skipped) return <SkipFlow />;
  }

  return (
    <LinkFormContents
      setShowModal={setShowModal}
      handleSubmit={handleSubmit}
      cancelButton
      error={error}
    />
  );
};

const EmailForm = () => {
  const navigate = useNavigate();
  return (
    <EmailFlowStyles>
      <p>
        Check your email for the link to log in and complete your registration
        for the GO Loyalty Program!
      </p>

      <PrimaryButton
        onClick={(e) => {
          e.preventDefault();
          navigate("/");
        }}
      >
        Done
      </PrimaryButton>
    </EmailFlowStyles>
  );
};

const SignupFlow = ({ setShowModal }) => {
  return (
    <SignupContainer>
      <Tabs />
      <Routes>
        <Route path="*" element={<Navigate to="/signup/profile" />} />
        <Route path="/profile" element={<ProfileForm />} />

        <Route
          path="/link"
          element={<LinkForm setShowModal={setShowModal} />}
        />

        <Route path="/email" element={<EmailForm />} />
      </Routes>
    </SignupContainer>
  );
};

export default SignupFlow;
