import styled from "styled-components";
import { useMe } from "../../store/me";
import { truncateString } from "utils";

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: ${(props) => props.theme.modal.mobile.minWidth};

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    justify-content: flex-start;
    width: ${(props) => props.theme.modal.small.minWidth};
    height: ${(props) => props.theme.modal.small.minHeight};
    padding: initial;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 31px auto 18px auto;
    height: 100%;
  }

  p {
    padding: 0;
    margin: 0;
  }

  .balance,
  .redeem {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .points-summary {
    font-family: var(--font-secondary);
    font-size: 17px;
    text-align: left;
    color: ${(props) => props.theme.colors.orange};
    text-transform: uppercase;
  }

  .current-balance-title,
  .redeem-title {
    font-family: var(--font-primary);
    font-weight: 700;
    font-size: 21px;
    line-height: 31.5px;
  }

  .current-balance,
  .required,
  .remaining-balance {
    font-family: var(--font-primary);
    font-size: 19px;
    line-height: 28.5px;
  }

  .cancel {
    margin-right: 15px;
  }
`;

const PrimaryButton = styled.button`
  margin-top: 10px;
  ${(props) => props.theme.buttons.primaryButton}
`;

const SecondaryButton = styled.button`
  margin-top: 10px;
  ${(props) => props.theme.buttons.secondaryButton}
`;

const ConfirmRedeem = ({ reward, onCancel, onConfirm }) => {
  const { data: me } = useMe();

  if (!reward) return null;

  return (
    <Container>
      <div className="content-container">
        <div>
          <p className="points-summary">Points Summary</p>
          <div className="balance">
            <p className="current-balance-title">Current Balance:</p>
            <p className="current-balance">{`${me.current_balance} pts`}</p>
          </div>
          <div className="redeem">
            <p className="redeem-title">{`Redeem ${truncateString(
              reward.name,
              22
            )}?`}</p>
            <p className="required">{`Required: ${reward.point_cost} pts`}</p>
            <p className="remaining-balance">{`Remaining Balance: ${
              me.current_balance - reward.point_cost
            } pts`}</p>
          </div>
        </div>
        <div className="buttons">
          <SecondaryButton
            onClick={() => {
              onCancel();
            }}
            className="cancel"
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton onClick={() => onConfirm()} className="submit">
            Redeem
          </PrimaryButton>
        </div>
      </div>
    </Container>
  );
};

export default ConfirmRedeem;
