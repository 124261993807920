import { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import styled from "styled-components";
import * as Yup from "yup";
import { UnauthorizedError, NotFoundError, ConflictError, states } from "utils";
import { Spinner } from "components";

const LinkFlowStyles = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .max-width {
    max-width: 295px;
  }

  p {
    font-family: var(--font-primary);
    font-size: 17px;
    line-height: 120%;
  }

  .error {
    font-family: var(--font-primary);
    color: red;
    max-width: 350px;
    text-align: center;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .input-row {
    display: flex;
    flex-direction: column;
  }

  label {
    font-family: var(--font-primary);
    font-weight: 600;
    font-size: 18px;
    line-height: 200%;
    letter-spacing: -0.01em;
  }

  input {
    height: 36px;
    width: 292px;
    padding-left: 10px;
  }

  select {
    height: 42px;
    width: 310px;
  }

  .error {
    font-family: var(--font-primary);
    color: red;
  }

  .button-container {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
  }
`;

const PrimaryButton = styled.button`
  ${(props) => props.theme.buttons.primaryButton}
`;

const SecondaryButton = styled.button`
  ${(props) => props.theme.buttons.secondaryButton}
  margin-right: 15px;
`;

const ButtonContents = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .text {
    margin-right: 5px;
  }
`;

const LinkFormContents = ({
  cancelButton,
  setShowModal,
  handleSubmit,
  error,
  me,
}) => {
  const [errorMessage, setErrorMessage] = useState();
  const lpNumberRegex = /^[0-9A-Za-z]+/;
  const validationSchema = Yup.object().shape({
    licensePlate: Yup.string()
      .matches(
        lpNumberRegex,
        "Please add only the license plate number. Do not add the state abreviation"
      )
      .required("required"),
    state: Yup.string().required("required"),
  });

  useEffect(() => {
    const errorCodes = [
      {
        type: NotFoundError,
        message:
          "Your account does not exist. Please contact GO Loyalty Support for help.",
      },
      {
        type: ConflictError,
        message:
          "Your account has already been linked to another email address.  Please contact GO Loyalty Support for help.",
      },
      {
        type: UnauthorizedError,
        message:
          "You are not authorized to link to this account.  Please contact GO Loyalty Support for help.",
      },
    ];

    let message =
      "Something unexpected happened.  Please contact GO Loyalty Support for help if the issue continues.";
    if (error) {
      errorCodes.forEach((code) => {
        if (error instanceof code.type) {
          message = code.message;
        }
      });
    }

    setErrorMessage(message);
  }, [error, errorMessage]);

  return (
    <LinkFlowStyles>
      <p className="max-width">
        Connect your GO Loyalty account with your existing membership!
      </p>
      {error && <div className="error">{errorMessage}</div>}
      <Formik
        initialValues={{
          licensePlate: "",
          state: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors, values }) => {
          let isValidated = true;
          if (errors.licensePlate || values.licensePlate === "") {
            isValidated = false;
          }

          const isDisabled = isSubmitting || !isValidated;

          return (
            <Form>
              <FormContainer>
                <div className="input-row">
                  {me && me.user && (
                    <>
                      <label>First Name</label>
                      <div>{me.user.first_name}</div>
                      <label>Last Name</label>
                      <div>{me.user.last_name}</div>
                      <label>Email</label>
                      <div>{me.user.email}</div>
                    </>
                  )}
                  <label htmlFor="licensePlate">License Plate</label>
                  <Field type="text" name="licensePlate" />
                  <ErrorMessage
                    className="error"
                    name="licensePlate"
                    component="div"
                  />
                  <label htmlFor="state">State</label>
                  <Field as="select" name="state">
                    <option value="" label="Select a state" />
                    {states.map((state) => (
                      <option
                        key={state.code}
                        value={state.code}
                        label={state.name}
                      />
                    ))}
                  </Field>
                  <ErrorMessage
                    className="error"
                    name="state"
                    component="div"
                  />
                  <div className="button-container">
                    {cancelButton && (
                      <SecondaryButton
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowModal(true);
                        }}
                      >
                        Skip
                      </SecondaryButton>
                    )}
                    <PrimaryButton type="submit" disabled={isDisabled}>
                      {isSubmitting ? (
                        <ButtonContents>
                          <div className="text">Linking...</div>
                          <Spinner size="small" />
                        </ButtonContents>
                      ) : (
                        <div>Link my Account</div>
                      )}
                    </PrimaryButton>
                  </div>
                </div>
              </FormContainer>
            </Form>
          );
        }}
      </Formik>
    </LinkFlowStyles>
  );
};

export default LinkFormContents;
