import { useState, useCallback } from "react";
import styled from "styled-components";
import { useRewards } from "../../store/rewards";
import { useEventTypes } from "../../store/eventTypes";
import { useMe } from "../../store/me";
import { Modal, ConfirmRedeem, BarcodeRedeem } from "../../components";
import { useApi } from "../../hooks";

const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 100px;
  overflow-x: scroll;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    padding-top: initial;
    overflow-x: initial;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    margin-top: initial;
    padding-left: 40px;
  }

  .title {
    font-size: 34px;
    line-height: 51px;
    font-family: var(--font-secondary);
    margin-bottom: 5px;
    color: ${(props) => props.theme.colors.orange};
  }
`;

const Item = styled.div`
  width: 100%;
  min-width: 500px;
  border-top: 1px dashed ${(props) => props.theme.colors.gray};

  .row {
    display: flex;
    flex-direction: row;
    min-width: 50px;
    @media (min-width: ${(props) => props.theme.breakpoints.large}) {
      min-width: 720px;
    }
  }

  .item-title,
  .points {
    display: flex;
    font-family: var(--font-primary);
    font-weight: 700;
    font-size: 15px;
    line-height: 250%;
    letter-spacing: -0.01em;

    @media (min-width: ${(props) => props.theme.breakpoints.large}) {
      font-size: 21px;
      line-height: 150%;
    }
  }

  .item-title {
    flex: 2;
  }

  .button,
  .points {
    flex: 0.7;
  }

  .points {
    color: ${(props) => props.theme.colors.orange};
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .message {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-family: var(--font-primary);
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.01em;
    color: ${(props) => props.theme.colors.gray};

    @media (min-width: ${(props) => props.theme.breakpoints.large}) {
      font-size: 18px;
    }
  }
`;

const PrimaryButton = styled.button`
  ${(props) => props.theme.buttons.primaryButton}
`;

// Determine if this user is targeted by the reward's attributes
// Tier, site, and plan aren't always specified on the reward (i.e. null)
// Not specified equals a match
// Tier, site, and plan need to match
const filterReward = (reward, me) => {
  const validateUser = (obj, key) => {
    const value = obj && obj[key] ? obj[key].id : null;
    return value;
  };

  const rewardTier = reward.tier;
  const rewardPlan = reward.plan;
  const rewardSite = reward.site;
  const userTier = validateUser(me, "tier");
  const userPlan = validateUser(me, "plan");
  const userSite = me.customer.site;

  const matches = (reward, user) => {
    if (reward === null) {
      return true;
    } else {
      return reward === user;
    }
  };

  const tierMatches = matches(rewardTier, userTier);
  const planMatches = matches(rewardPlan, userPlan);
  const siteMatches = matches(rewardSite, userSite);

  return tierMatches && planMatches && siteMatches;
};

const Redeem = () => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showBarcodeModal, setShowBarcodeModal] = useState(false);
  const { data: rewards } = useRewards();
  const { data: me, refetch: refetchMe } = useMe();
  const filteredRewards = rewards.filter((reward) => filterReward(reward, me));
  const { data: eventTypes } = useEventTypes();
  const { fetchPOST } = useApi();

  const [currentReward, setCurrentReward] = useState();
  const [redeemEvent, setRedeemEvent] = useState();

  const submitRedemption = async () => {
    if (!currentReward) {
      throw Error("No Current Reward");
    }
    const reward = currentReward;
    const eventType = eventTypes.find(
      (eventType) => eventType.code === "REDEEM_REWARD"
    );

    const event = await fetchPOST("events", {
      name: reward.name,
      description: reward.description,
      event_type: eventType.id,
      data: { reward_id: reward.id },
      event_time: new Date().toISOString(),
      customer: me.customer.id,
      site: me.customer.site,
      plan: me.customer.plan,
      external_key: Date.now(),
    });

    await refetchMe();
    setShowConfirmModal(false);
    setRedeemEvent(event);
    setShowBarcodeModal(true);
  };

  const onConfirmBarcodeModal = useCallback(() => {
    setShowBarcodeModal(false);
    setCurrentReward(null);
    setRedeemEvent(null);
  }, [setShowBarcodeModal, setCurrentReward, setRedeemEvent]);

  return (
    <>
      <Container>
        <div className="title">Redeem Your Points!</div>
        {filteredRewards
          .sort((a, b) => (a.point_cost > b.point_cost ? 1 : -1))
          .map((reward) => {
            const isDeficit = reward.point_cost > me.current_balance;
            const message = isDeficit ? "Not enough points" : "";
            return (
              <Item
                key={reward.id}
                title={reward.name}
                points={reward.point_cost}
              >
                <div className="row">
                  <p className="item-title">{reward.name}</p>
                  <p className="points">{`${reward.point_cost} Points`}</p>
                  <div className="button">
                    <PrimaryButton
                      onClick={() => {
                        setShowConfirmModal(true);
                        setCurrentReward(reward);
                      }}
                      disabled={isDeficit}
                    >
                      Redeem
                    </PrimaryButton>
                  </div>
                  <p className="message">{message}</p>
                </div>
              </Item>
            );
          })}
      </Container>

      <Modal open={showConfirmModal} onClose={() => setShowConfirmModal(false)}>
        <ConfirmRedeem
          reward={currentReward}
          onConfirm={() => submitRedemption()}
          onCancel={() => setShowConfirmModal(false)}
        />
      </Modal>

      <Modal open={showBarcodeModal} onClose={() => setShowBarcodeModal(false)}>
        <BarcodeRedeem
          reward={currentReward}
          event={redeemEvent}
          closeModal={onConfirmBarcodeModal}
        />
      </Modal>
    </>
  );
};

export default Redeem;
