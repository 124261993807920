import styled from "styled-components";
import { Routes, Route, useNavigate } from "react-router-dom";

const SectionFooter = styled.footer`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  // Pin the footer to the bottom of the page
  // Using the techique from here: https://css-tricks.com/a-clever-sticky-footer-technique/
  position: sticky;
  top: 100vh;
`;

const FooterStripe = styled.div`
  display: flex;
  height: 247px;
  background-color: ${(props) => props.theme.colors.orange};
`;

const FooterImage = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  width: 222px;
  height: 175px;
  background-image: url(${(props) => props.theme.images.footer});
  background-size: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    height: 268px;
    width: 340px;
    bottom: 25px;
  }
`;

const BlueStripe = styled.div`
  display: flex;
  height: 165px;
  margin-bottom: 150px;
  background-color: ${(props) => props.theme.colors.blue};
  width: 100%;
`;

const BlueStripeContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 405px;
  height: 150px;

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    padding-left: 60px;
    padding-right: 60px;
    flex-direction: row;
    justify-content: space-around;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    padding-left: 100px;
    padding-right: 100px;
    flex-direction: row;
    justify-content: space-around;
  }

  .title {
    font-family: var(--font-secondary);
    font-style: italic;
    font-weight: 600;
    font-size: 40px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;

    @media (min-width: ${(props) => props.theme.breakpoints.large}) {
      font-size: 54px;
    }
  }
`;

const FlagImage = styled.div`
  width: 222px;
  height: 175px;
  background: no-repeat url(${(props) => props.theme.images.flag});
  background-size: 100%;
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    width: 500px;
    height: 269px;
    display: block;
  }
`;

const PrimaryButton = styled.button`
  ${(props) => props.theme.buttons.primaryButton}
  background-color: #000;
  border: #000;
  white-space: nowrap;
  margin-top: 20px;

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    margin-top: 0;
  }
`;

const Footer = () => {
  const navigate = useNavigate();
  return (
    <SectionFooter>
      <Routes>
        <Route
          path="*"
          element={
            <>
              <FooterStripe />
              <FooterImage />
            </>
          }
        />
        <Route
          path="/login"
          element={
            <>
              <BlueStripe />
              <BlueStripeContent>
                <FlagImage />
                <div className="title">Join Go Loyalty Today!</div>
                <PrimaryButton onClick={() => navigate("/signup")}>
                  Get Started
                </PrimaryButton>
              </BlueStripeContent>
              <FooterStripe />
              <FooterImage />
            </>
          }
        />
      </Routes>
    </SectionFooter>
  );
};

export default Footer;
