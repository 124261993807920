import {
  createContext,
  useContext as _useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useApi } from "../hooks";
import { Spinner } from "components";

export default function createStore(endpoint) {
  const Context = createContext();
  const useContext = () => _useContext(Context);
  const Provider = ({ children }) => {
    const { fetchGET } = useApi();

    const [data, setData] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);

    const initialFetch = useCallback(async () => {
      try {
        const newData = await fetchGET(endpoint);
        setData(newData);
        setLoading(false);
        setError(undefined);
      } catch (e) {
        setError(e);
      }
    }, [fetchGET, setData, setLoading, setError]);

    useEffect(() => {
      initialFetch();
    }, [initialFetch]);

    return (
      <Context.Provider value={{ data, loading, error, refetch: initialFetch }}>
        {children}
      </Context.Provider>
    );
  };

  const Gate = ({ children, emptyComponent, noSpinner }) => {
    const { data, loading } = useContext();

    if (loading && !noSpinner) {
      return <Spinner />;
    }

    if (!data) {
      if (emptyComponent) {
        return emptyComponent;
      } else {
        return null;
      }
    }

    return children;
  };

  return {
    useContext: useContext,
    Provider: Provider,
    Gate: Gate,
  };
}
