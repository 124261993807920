import React, { Component } from "react";
import Routes from "./pages";
import { AuthProvider } from "store/auth";
import Theme from "./components/Theme";

class App extends Component {
  render() {
    return (
      <Theme>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </Theme>
    );
  }
}

export default App;
