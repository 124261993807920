import React, { useState } from "react";
import styled from "styled-components";
import { Formik, Form, ErrorMessage, Field } from "formik";

import { useApi } from "../../hooks";

const PrimaryButton = styled.button`
  margin-top: 20px;
  ${(props) => props.theme.buttons.primaryButton}
`;

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    align-items: flex-start;
  }

  .text,
  .error {
    font-family: var(--font-primary);
    font-size: 19px;
    line-height: 28.5px;
  }

  label {
    font-family: var(--font-primary);
    font-weight: 600;
    font-size: 18px;
    line-height: 200%;
    letter-spacing: -0.01em;
  }

  input {
    height: 36px;
    width: 292px;
    padding-left: 10px;
  }

  .error {
    color: red;
  }
`;

const ChangePasswordForm = () => {
  const { fetchPOST } = useApi();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setError(false);
      setSuccess(false);

      const data = await fetchPOST("change-password", {
        previous_password: values.previousPassword,
        proposed_password: values.proposedPassword,
      });

      if (data.success) {
        setSuccess(true);
      } else {
        setError(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Styles>
      <Formik
        initialValues={{
          previousPassword: "",
          proposedPassword: "",
        }}
        onSubmit={handleSubmit}
        validate={(values) => {
          const errors = {};
          if (!values.previousPassword) {
            errors.previousPassword = "Required";
          }
          if (!values.proposedPassword) {
            errors.proposedPassword = "Required";
          }
          return errors;
        }}
      >
        {({ isSubmitting, errors, values, touched, status }) => {
          let isValidated = true;
          if (
            errors.previousPassword ||
            errors.proposedPassword ||
            values.previousPassword === "" ||
            values.proposedPassword === ""
          ) {
            isValidated = false;
          }
          return (
            <Form>
              <div>
                <label>Previous Password</label>
                <div>
                  <Field
                    name="previousPassword"
                    type="password"
                    placeholder="Previous Password"
                    className={
                      errors.previousPassword && touched.previousPassword
                        ? "error"
                        : ""
                    }
                  />
                </div>
                <ErrorMessage
                  name="previousPassword"
                  component="div"
                  className="error"
                />
              </div>

              <div>
                <label>Proposed Password</label>
                <div>
                  <Field
                    name="proposedPassword"
                    type="password"
                    placeholder="Proposed Password"
                    className={
                      errors.proposedPassword && touched.proposedPassword
                        ? "error"
                        : ""
                    }
                  />
                </div>
                <ErrorMessage
                  name="proposedPassword"
                  component="div"
                  className="error"
                />
              </div>

              <PrimaryButton type="submit" disabled={!isValidated}>
                Change Password
              </PrimaryButton>

              {isSubmitting && <div>Submitting...</div>}
              {error && <div className="error">Error</div>}
              {success && <div className="success">Success</div>}
            </Form>
          );
        }}
      </Formik>
    </Styles>
  );
};

export default ChangePasswordForm;
