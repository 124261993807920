import React from "react";
import styled from "styled-components";
import image from "../assets/racetrack.png";

const Container = styled.section`
  display: flex;
  width: 100%;
  max-width: 936px;
  flex-direction: column;
  justify-content: center;
  margin: 50px auto 50px auto;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    flex-direction: row;
  }

  .title-container {
    display: flex;
    flex: 40%;
  }

  .title {
    font-family: var(--font-secondary);
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.orange};

    @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
      font-size: 54px;
      white-space: nowrap;
    }
  }

  .content {
    display: flex;
    flex: 60%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const RaceTrackImage = styled.img`
  width: 100%;
  height: auto;
  vertical-align: middle;
`;

export default function Login() {
  return (
    <Container>
      <div className="title-container">
        <p className="title">How It Works</p>
      </div>

      <div className="content">
        <RaceTrackImage src={image} />
      </div>
    </Container>
  );
}
