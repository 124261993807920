import styled from "styled-components";
import { Routes, Route } from "react-router-dom";
import {
  PointsHistory,
  Racetrack,
  ProfileForm,
  Redeem,
  Status,
  ChangePasswordForm,
  NoCustomer,
} from "../components";
import { TransactionsProvider, TransactionsGate } from "store/transactions";
import { TiersProvider } from "store/tiers";
import { RewardsProvider, RewardsGate } from "store/rewards";
import { EventTypesProvider, EventTypesGate } from "store/eventTypes";
import { useMe } from "store/me";

const ResponsiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    flex-direction: row;
  }
`;

const ResponsiveRedeemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    flex-direction: row;
  }
`;

const RaceTrackWrapper = styled.div`
  max-width: 300px;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    display: flex;
    flex: 1;
    padding-left: 20px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    max-width: 50%;
    padding-left: initial;
  }
`;

const StatusWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    display: flex;
    flex: 1;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    padding-left: 20px;
    max-width: 50%;
  }
`;

const PointsWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 300px;

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    max-width: 100%;
  }
`;

const RedeemWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 300px;
  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    max-width: 100%;
  }
`;

export default function Dashboard() {
  const me = useMe();

  if (!me?.data?.customer) {
    return <NoCustomer />;
  }

  return (
    <TiersProvider>
      <EventTypesProvider>
        <EventTypesGate>
          <Routes>
            <Route
              path="*"
              element={
                <ResponsiveWrapper>
                  <StatusWrapper>
                    <Status />
                  </StatusWrapper>
                  <RaceTrackWrapper>
                    <Racetrack />
                  </RaceTrackWrapper>
                </ResponsiveWrapper>
              }
            />
            <Route
              path="/history"
              element={
                <ResponsiveWrapper>
                  <Status />
                  <TransactionsProvider>
                    <TransactionsGate>
                      <PointsWrapper>
                        <PointsHistory />
                      </PointsWrapper>
                    </TransactionsGate>
                  </TransactionsProvider>
                </ResponsiveWrapper>
              }
            />
            <Route
              path="/redeem"
              element={
                <ResponsiveRedeemWrapper>
                  <Status />
                  <RewardsProvider>
                    <RewardsGate>
                      <RedeemWrapper>
                        <Redeem />
                      </RedeemWrapper>
                    </RewardsGate>
                  </RewardsProvider>
                </ResponsiveRedeemWrapper>
              }
            />

            <Route
              exact
              path="/profile"
              element={
                <div>
                  <ProfileForm />
                  <ChangePasswordForm />
                </div>
              }
            />
          </Routes>
        </EventTypesGate>
      </EventTypesProvider>
    </TiersProvider>
  );
}
