import { useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { logout } from "store/auth";
import { ChevronDown, ProfileIcon } from "../icons";
import { DesktopOnly, TabletOnly, MobileOnly } from "components";
import headerImage from "../../assets/small-header.png";
import mobileHeaderImage from "../../assets/mobile-header.png";
import { useMe, MeGate } from "store/me";
import { useAuth, checkLoggedIn } from "../../store/auth";

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    margin-bottom: 50px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    margin-bottom: -200px;
  }
`;

const HeaderStripe = styled.div`
  height: 220px;
  position: absolute;
  top: 160px;
  background-color: ${(props) => props.theme.colors.orange};
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    height: 283px;
    top: 120px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    position: relative;
    height: 174px;
    top: 70px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  top: 80px;
  left: 0;
  right: 0;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 80%;
  border-bottom: 1px dashed ${(props) => props.theme.colors.orange};

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    max-width: ${(props) => props.theme.breakpoints.small};
    top: 50px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    height: auto;
    max-width: ${(props) => props.theme.breakpoints.medium};
    top: -170px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    max-width: calc(${(props) => props.theme.breakpoints.large} - 60px);
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  top: 0px;
  height: 100%;
  width: 100%;
  max-width: 280px;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    max-width: 347px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    max-width: initial;
  }
`;

const HeaderImage = styled.img`
  width: 100%;
  height: auto;
  vertical-align: middle;
`;

const ProfileSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: -6%;
  right: 0;
  border: none;
  background-color: transparent;
  padding: 10px;
  z-index: 1;
  min-width: 150px;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    top: -4%;
    right: 2%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    top: 0;
  }

  & button {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }

  & .chevron {
    display: flex;
    justify-content: center;
    transition: transform 0.7s;

    &.up-chevron {
      transform: rotate(0.5turn);
    }
  }

  & .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    max-height: 0px;
    transition: max-height 0.7s ease;
    overflow: hidden;
    padding: 10px;
  }

  & .menu.open {
    visibility: visible;
    max-height: 100px;
  }

  & .menu.closed {
    visibility: hidden;
  }

  & .logout {
    margin-top: 20px;
    color: ${(props) => props.theme.colors.blue};
    font-family: var(--font-primary);
    font-size: 18px;
    font-weight: 800;

    &:hover {
      cursor: pointer;
    }
  }
`;

const ProfileCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-right: 5px;
  background-color: ${(props) => props.theme.colors.orange};
`;

const ProfileName = styled.p`
  font-family: var(--font-primary);
  margin-right: 5px;
`;

const LoyaltyInfo = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 40px;
  justify-content: center;
  align-items: center;

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    align-items: flex-start;
  }

  .signup-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title-container {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 450px;
  }

  .authenticated-container .title-container {
    @media (min-width: ${(props) => props.theme.breakpoints.small}) {
      justify-content: flex-start;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.large}) {
      max-width: 800px;
    }
  }

  & p.title {
    margin-bottom: 0;
    font-size: 40px;
    line-height: 120%;
    font-family: var(--font-secondary);
    font-weight: 800;
    color: ${(props) => props.theme.colors.orange};
    text-align: center;

    @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
      font-size: 50px;
    }
  }

  .authenticated-container .title {
    @media (min-width: ${(props) => props.theme.breakpoints.small}) {
      text-align: left;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
      margin-left: 50px;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.large}) {
      margin-left: 100px;
    }
  }

  .description-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px 0 20px;

    @media (min-width: ${(props) => props.theme.breakpoints.small}) {
      padding: 0;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
      flex-direction: row;
      justify-content: space-between;
      padding: 0 50px 0 50px;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.large}) {
      padding: 0 100px 0 100px;
    }
  }

  & p.description {
    flex: 1;
    font-size: 19px;
    line-height: 130%;
    font-family: var(--font-primary);
    text-align: center;
    width: 100%;

    @media (min-width: ${(props) => props.theme.breakpoints.small}) {
      max-width: 450px;
      text-align: left;
      padding: initial;
    }
  }

  p.description:first-child {
    @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
      padding-right: 20px;
    }
  }

  p.description:nth-child(2) {
    @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
      padding-left: 20px;
    }
  }
`;

const HeaderProfile = () => {
  const [showDropDown, setShowDropDown] = useState(false);
  const { data: me } = useMe();
  return (
    <ProfileSection>
      <button
        onClick={() => {
          setShowDropDown(!showDropDown);
        }}
      >
        <ProfileCircle>
          <ProfileIcon />
        </ProfileCircle>
        <ProfileName>
          {me?.customer?.first_name || me?.user.first_name}{" "}
          {me?.customer?.last_name || me?.user.last_name}
        </ProfileName>
        <div
          className={
            showDropDown ? "chevron up-chevron" : "chevron down-chevron"
          }
        >
          <ChevronDown />
        </div>
      </button>
      {showDropDown ? (
        <div className="menu open">
          <Link to="/dashboard/profile">View Profile</Link>
          <button className="logout" onClick={() => logout()}>
            Log Out
          </button>
        </div>
      ) : (
        <div className="menu closed"></div>
      )}
    </ProfileSection>
  );
};

const Header = () => {
  const { state } = useAuth();
  const loggedIn = checkLoggedIn(state);
  const { pathname } = useLocation();

  return (
    <Section>
      <HeaderStripe />
      <HeaderContent>
        {loggedIn ? (
          <MeGate>
            <HeaderProfile noSpinner />
          </MeGate>
        ) : null}
        <MobileOnly>
          <ImageWrapper>
            <HeaderImage src={mobileHeaderImage} />
          </ImageWrapper>
        </MobileOnly>
        <TabletOnly>
          <ImageWrapper>
            <HeaderImage src={mobileHeaderImage} />
          </ImageWrapper>
        </TabletOnly>
        <DesktopOnly>
          <ImageWrapper>
            <HeaderImage src={headerImage} />
          </ImageWrapper>
        </DesktopOnly>
        <LoyaltyInfo>
          {loggedIn ? (
            <div className="authenticated-container">
              <div className="title-container">
                <p className="title">Go Loyalty Headline</p>
              </div>
              <div className="description-container">
                <p className="description">
                  Welcome to the GO Loyalty program! Earn points with every wash
                  and redeem on everything from free wash upgrades to discounts
                  and more. With every wash, you earn points that gets you
                  closer to a higher tier, Bronze, Silver, or Gold, where your
                  rewards are even more! Thank you for being part of the GO
                  Carwash family.
                </p>
                <p className="description">
                  Welcome to the GO Loyalty program! Earn points with every wash
                  and redeem on everything from free wash upgrades to discounts
                  and more. With every wash, you earn points that gets you
                  closer. Higher tier, Bronze, Silver, or Gold, where your
                  rewards are even.
                </p>
              </div>
            </div>
          ) : pathname === "/login-concept" ? (
            <p className="title">Log into Go Loyalty</p>
          ) : (
            <div className="signup-container">
              <p className="title">Sign Up for Go Loyalty</p>
              <p className="description">
                This is how it works. Lorem ipsum dolor sit amet, consectetuer
                adipiscing elit. Donec odio. Quisque volutpat mattis eros.
                Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra
                non, semper suscipit, posuere a, pede.
              </p>
            </div>
          )}
        </LoyaltyInfo>
      </HeaderContent>
    </Section>
  );
};

export default Header;
