import styled from "styled-components";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useMe } from "store";

const Container = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-right: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    align-items: flex-start;
  }

  .name {
    font-family: var(--font-secondary);
    font-size: 32px;
    margin-bottom: 5px;
    padding-bottom: 0;
    text-transform: uppercase;
  }

  .member-id {
    font-family: var(--font-primary);
    font-size: 18px;
    font-weight: 800;
    margin-top: 0;
    margin-bottom: 8px;
    padding-top: 0;
  }

  .section-title-italicized {
    color: ${(props) => props.theme.colors.orange};
    font-size: 16px;
    font-family: var(--font-secondary);
    text-transform: uppercase;
    font-style: italic;
    line-height: 150%;
    margin-top: 30px;
    margin-bottom: 8px;
  }

  .section-title {
    color: ${(props) => props.theme.colors.orange};
    font-size: 17px;
    font-family: var(--font-secondary);
    text-transform: uppercase;
    line-height: 165%;
    margin-top: 30px;
    margin-bottom: 8px;
  }
  }

  .tier {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 54px;
    padding-left: 10px;
    background: linear-gradient(90deg, #969696 0%, #e6e6e6 100%);
    min-width: 280px;

    @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
      min-width: 350px;
    }
  }

  .tier.tier-bronze {
    background: linear-gradient(90deg, #76492d 0%, #c19f6c 100%);
  }

  .tier.tier-silver {
    background: linear-gradient(90deg, #969696 0%, #e6e6e6 100%);
  }

  .tier.tier-gold {
    background: linear-gradient(90deg, #c69f18 0%, #ffd600 100%);
  }

  .tier-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 34px;
  }

  .tier-level {
    font-size: 30px;
    font-family: var(--font-secondary);
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
    margin-right: 10px;
    font-style: italic;
  }

  .tier-label {
    display: flex;
    align-self: flex-start;
    font-size: 15px;
    font-family: var(--font-secondary);
    color: #fff;
    font-style: italic;
  }

  .current {
    font-size: 21px;
    font-family: var(--font-primary);
  }

  .current-bold {
    font-weight: 800;
     margin: 0 0 5px 0;
  }

  .current-text {
    margin: 0 0 20px 0;
  }
`;

const Button = styled.button`
  margin-top: 10px;
  ${(props) => props.theme.buttons.primaryButton}
`;

const Status = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data: me } = useMe();

  const {
    customer,
    tier,
    current_balance,
    lifetime_points,
    past_year_points,
    first_name,
    last_name,
  } = me;

  const isPointsHistoryPage = pathname === "/home/history";

  return (
    <Container>
      <p className="name">
        {customer?.first_name || first_name} {customer?.last_name || last_name}
      </p>
      <p className="member-id">Member# {customer?.sitewatch_customer_id}</p>
      <Link to="/dashboard/profile">View my Profile</Link>
      <div>
        <p className="section-title-italicized">My Rewards & Benefits</p>
        <div
          className={`tier${tier ? ` tier-${tier.id} tier-${tier.code}` : ""}`}
        >
          <div className="tier-text">
            <p className="tier-level">{tier && tier.name}</p>
            <p className="tier-label">Tier Member</p>
          </div>
        </div>
      </div>
      <div>
        <p className="section-title">Points Summary</p>
        <p className="current current-bold">
          Current Balance: {current_balance}
        </p>
        <p className="current current-text">
          {past_year_points} Points Earned This Year
          <br />
          {lifetime_points} Lifetime Points Earned
        </p>
      </div>
      <Button
        disabled={isPointsHistoryPage}
        onClick={() => navigate("/dashboard/history")}
      >
        View My History
      </Button>
      <Button onClick={() => navigate("/dashboard/redeem")}>
        Redeem Points
      </Button>
    </Container>
  );
};

export default Status;
