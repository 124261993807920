import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { MeProvider, MeGate } from "store/me";
import { Layout, RequireAuth, NoCustomer } from "../components";
import Dashboard from "./dashboard";
import Callback from "./callback";
import Login from "./login";
import LoginConcept from "./loginConcept";
import Signup from "./signup";

export default function Pages() {
  return (
    <MeProvider>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route
              path="/dashboard/*"
              element={
                <RequireAuth>
                  <MeGate emptyComponent={<NoCustomer />}>
                    <Dashboard />
                  </MeGate>
                </RequireAuth>
              }
            />
            <Route path="/callback" element={<Callback />} />
            <Route path="/login" element={<Login />} />
            <Route path="/login-concept" element={<LoginConcept />} />
            <Route path="/signup/*" element={<Signup />} />

            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </MeProvider>
  );
}
