import React, { useContext, useReducer, useEffect, useState } from "react";
import Amplify, { Auth } from "aws-amplify";
import jwt_decode from "jwt-decode";

// create react context and provider wrapper
const Context = React.createContext();
export const useAuth = () => useContext(Context);

// return awsconfig.json file
function getCognitoConfig() {
  return {
    Auth: {
      region: process.env.REACT_APP_COGNITO_AWS_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
      mandatorySignIn: false,
      cookieStorage: {
        domain: process.env.REACT_APP_COGNITO_COOKIE_SITE_DOMAIN,
        path: "/",
        expires: 365,
        secure: process.env.REACT_APP_COGNITO_COOKIE_SECURE === "true",
      },
      redirectSignIn: process.env.REACT_APP_COGNITO_CALLBACK_URL,
      redirectSignOut: process.env.REACT_APP_COGNITO_LOGOUT_URL,
    },
    API: {
      endpoints: [
        {
          name: "loyalty",
          endpoint: process.env.REACT_APP_API_URL,
        },
      ],
    },
  };
}

function getCognitoAuthConfig() {
  return {
    domain: process.env.REACT_APP_COGNITO_HOSTED_UI_DOMAIN,
    scope: [
      "phone",
      "email",
      "profile",
      "openid",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: process.env.REACT_APP_COGNITO_CALLBACK_URL,
    redirectSignOut: process.env.REACT_APP_COGNITO_LOGOUT_URL,
    responseType: "token",
  };
}

function configureCognito() {
  Amplify.configure(getCognitoConfig());
  Auth.configure({ oauth: getCognitoAuthConfig() });
}

export const AuthProvider = ({ children }) => {
  const [accessTokenCheckDone, setAccessTokenCheckDone] = useState(false);
  const reducer = (state, action) => {
    switch (action.type) {
      case "LOGIN_CALLBACK": {
        const { access_token, id_token, expires_at } = action;
        // localStorage.setItem("access_token", access_token);
        // localStorage.setItem("id_token", id_token);
        // localStorage.setItem("expires_at", expires_at);
        // localStorage.setItem("refresh_token", refresh_token);
        return {
          ...state,
          access_token,
          id_token,
          expires_at,
        };
      }
      case "LOGIN":
        //configureCognito();
        // Hub.listen("auth", ({ payload: { event, data } }) => {
        //   switch (event) {
        //     case "signIn":
        //       console.log("sign in", event, data);
        //       // this.setState({ user: data})
        //       break;
        //     case "signOut":
        //       console.log("sign out");
        //       // this.setState({ user: null })
        //       break;
        //     default:
        //       console.log("Default auth case not handled:", event);
        //       break;
        //   }
        // });
        // Auth.federatedSignIn();
        //Auth.signIn();
        return state;
      case "LOGOUT":
        localStorage.removeItem("access_token");
        localStorage.removeItem("id_token");
        localStorage.removeItem("expires_at");
        // localStorage.removeItem("refresh_token");
        return {
          ...state,
          access_token: null,
          id_token: null,
          expires_at: null,
          refresh_token: null,
        };
      case "SET_USER":
        return {
          ...state,
          user: action.user,
        };
      default:
    }
    return state;
  };

  const [state, dispatch] = useReducer(reducer, {
    //   access_token: localStorage.getItem("access_token"),
    //   id_token: localStorage.getItem("id_token"),
    //   expires_at: localStorage.getItem("expires_at"),
    access_token: null,
    id_token: null,
    expires_at: null,
    // refresh_token: localStorage.getItem("refresh_token"),
  });

  useEffect(() => {
    (async () => {
      await checkAccessToken(dispatch);
      setAccessTokenCheckDone(true);
    })();
  }, [dispatch]);

  const value = { state, dispatch };

  return (
    <Context.Provider value={value}>
      {accessTokenCheckDone && children}
    </Context.Provider>
  );
};

export const loginCallback = (dispatch, callback) => {
  dispatch({ type: "LOGIN_CALLBACK", ...callback });
};

export const login = () => {
  configureCognito();
  Auth.federatedSignIn();
};

export const logout = () => {
  configureCognito();
  Auth.signOut();
};

export const loginCallbackCognito = async (dispatch, callback) => {
  const sessionTokens = await getSessionTokens();
  configureCognito();
  // Hub.listen("auth", ({ payload: { event, data } }) => {
  //   switch (event) {
  //     case "signIn":
  //       console.log("sign in", event, data);
  //       // this.setState({ user: data})
  //       break;
  //     case "signOut":
  //       console.log("sign out");
  //       // this.setState({ user: null })
  //       break;
  //     default:
  //       console.log("Default auth case not handled:", event);
  //       break;
  //   }
  // });
  const user = await Auth.currentAuthenticatedUser();
  dispatch({
    type: "LOGIN_CALLBACK",
    ...sessionTokens,
  });
  return user;
};

export const setUser = (dispatch, user) => {
  dispatch({ type: "SET_USER", user });
};

async function getSessionTokens() {
  configureCognito();
  let currentSession;
  try {
    currentSession = await Auth.currentSession();
  } catch (e) {
    console.log(typeof e);
    console.log(e);
    // log out on error
    // logout();
    // TODO: where is a better place put this (hub listener?)
    // window.location = "/login";
    return {};
  }
  const access_token = currentSession.getAccessToken().getJwtToken();
  const id_token = currentSession.getIdToken().getJwtToken();
  // const refresh_token = currentSession.getRefreshToken().getJwtToken();
  const decoded_access_token = jwt_decode(access_token);
  const { exp: expires_at } = decoded_access_token;
  return {
    access_token,
    id_token,
    expires_at,
  };
}

export const checkAccessToken = async (dispatch) => {
  const sessionTokens = await getSessionTokens();
  dispatch({
    type: "LOGIN_CALLBACK",
    ...sessionTokens,
  });
};

export const checkLoggedIn = (state) => {
  if (state.expires_at === null) {
    return false;
  } else {
    const expiresAt = state.expires_at * 1000;
    return new Date().getTime() < expiresAt;
  }
};
