import React, { useEffect } from "react";
import { useAuth, loginCallbackCognito, setUser } from "store/auth";
import { useNavigate } from "react-router-dom";

export default function Callback() {
  const { dispatch } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const user = await loginCallbackCognito(dispatch);
      setUser(dispatch, user);
      navigate("/");
    })();
  }, [dispatch, navigate]);

  return <div>Loading...</div>;
}
