import styled from "styled-components";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { useNavigate } from "react-router-dom";
import { useMe } from "store";
import { useApi } from "hooks";
import { cleanPhoneNumber } from "utils";

const ProfileContainer = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    align-items: flex-start;
  }

  .name {
    font-family: var(--font-secondary);
    font-size: 32px;
    margin-bottom: 5px;
    padding-bottom: 0;
    text-transform: uppercase;
  }

  .member-id {
    font-family: var(--font-primary);
    font-size: 18px;
    font-weight: 800;
    margin-top: 0;
    margin-bottom: 8px;
    padding-top: 0;
  }

  .changes-description {
    font-family: var(--font-primary);
    font-size: 19px;
    line-height: 150%;
    letter-spacing: -0.01em;
    max-width: 490px;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    align-items: flex-start;
  }

  .input-row {
    display: flex;
    flex-direction: column;

    @media (min-width: ${(props) => props.theme.breakpoints.small}) {
      flex-direction: row;
    }
  }

  .label-column {
    font-family: var(--font-primary);
    display: flex;
    flex-direction: column;
  }

  .label-column.initial {
    max-width: 308px;
    @media (min-width: ${(props) => props.theme.breakpoints.small}) {
      margin-right: 40px;
    }
  }

  label {
    font-family: var(--font-primary);
    font-weight: 600;
    font-size: 18px;
    line-height: 200%;
    letter-spacing: -0.01em;
  }

  input {
    height: 36px;
    width: 292px;
    padding-left: 10px;
  }

  .error {
    font-family: var(--font-primary);
    color: red;
  }

  .inner-text {
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    padding: 0 0 2px 0;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;

    @media (min-width: ${(props) => props.theme.breakpoints.small}) {
      flex-direction: row;
    }
  }
`;

const PrimaryButton = styled.button`
  margin-top: 10px;
  ${(props) => props.theme.buttons.primaryButton}

  &.disabled {
    background-color: ${(props) => props.theme.colors.gray};
    border: 1px solid ${(props) => props.theme.colors.gray};
  }
`;

const SecondaryButton = styled.button`
  margin-top: 10px;
  ${(props) => props.theme.buttons.secondaryButton}

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    margin-right: 20px;
  }
`;

const ProfileForm = () => {
  const { data: me } = useMe();
  const navigate = useNavigate();
  const { fetchPATCH } = useApi();

  const { customer } = me;

  let isDisabled = false;

  const handleSubmit = async (values) => {
    values.phone = cleanPhoneNumber(values.phone);

    await fetchPATCH(`customers/${me.customer.id}`, {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone: values.phone,
    });
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required").nullable(),
    lastName: Yup.string().required("Required").nullable(),
    email: Yup.string().email("Invalid email").required("Required").nullable(),
    phone: Yup.string()
      .phone("US", false, "Phone number must be a valid US number")
      .required("Required")
      .nullable(),
  });

  return (
    <ProfileContainer>
      <p className="name">
        {customer.first_name} {customer.last_name}
      </p>
      <p className="member-id">Member# {customer.sitewatch_customer_id}</p>
      <p className="changes-description">
        Make any necessary changes to your profile, then click “Save changes” to
        save your changes and return to the dashboard.
      </p>
      <Formik
        initialValues={{
          firstName: customer.first_name || "",
          lastName: customer.last_name || "",
          email: customer.email || "",
          phone: customer.phone || "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors, values }) => {
          let isValidated = true;
          if (
            errors.firstName ||
            errors.lastName ||
            errors.email ||
            errors.phone ||
            values.firstName === "" ||
            values.lastName === "" ||
            values.emailName === "" ||
            values.phone === ""
          ) {
            isValidated = false;
          }

          isDisabled = isSubmitting || !isValidated;

          return (
            <Form>
              <FormContainer>
                <div className="input-row">
                  <div className="label-column initial">
                    <label htmlFor="firstName">
                      First name <div className="inner-text">* required</div>
                    </label>
                    <Field type="text" name="firstName" placeholder="Jane" />
                    <ErrorMessage
                      className="error"
                      name="firstName"
                      component="div"
                    />
                  </div>
                  <div className="label-column">
                    <label htmlFor="lastName">
                      Last name <div className="inner-text">* required</div>
                    </label>
                    <Field type="text" name="lastName" placeholder="Doe" />
                    <ErrorMessage
                      className="error"
                      name="lastName"
                      component="div"
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div className="label-column initial">
                    <label htmlFor="email">
                      Email address <div className="inner-text">* required</div>
                    </label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="example@example.com"
                    />
                    <ErrorMessage
                      className="error"
                      name="email"
                      component="div"
                    />
                  </div>
                  <div className="label-column">
                    <label htmlFor="phone">
                      Phone number <div className="inner-text">* required</div>
                    </label>
                    <Field
                      type="tel"
                      name="phone"
                      placeholder="(111)111-1111"
                    />
                    <ErrorMessage
                      className="error"
                      name="phone"
                      component="div"
                    />
                  </div>
                </div>
                <div className="button-container">
                  <SecondaryButton
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/dashboard");
                    }}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    type="submit"
                    className={isDisabled ? "disabled" : ""}
                  >
                    Save Changes
                  </PrimaryButton>
                </div>
              </FormContainer>
            </Form>
          );
        }}
      </Formik>
    </ProfileContainer>
  );
};

export default ProfileForm;
