import { Header, Footer } from "../../components";
import styled from "styled-components";

const Main = styled.main`
  display: flex;
  justify-content: center;
  margin: 0 auto 0 auto;
  max-width: ${(props) => props.theme.dimensions.maxWidth};
  width: 100%;
  box-sizing: border-box;
  padding: 50px 20px;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    padding: 50px 100px;
  }
`;

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </>
  );
};

export default Layout;
