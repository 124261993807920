// imports
import { useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(1px);
  transition: background-color 0.3s ease-in-out;
  transition-delay: 2000ms;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: "all";
`;

const Content = styled.div`
  min-width: 320px;
  min-height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  z-index: 2;
  background-color: #fff;
`;

// Using Portal to create modal behavior
function PortalContainer({ children }) {
  const el = useMemo(() => document.createElement("div"), []);

  useEffect(() => {
    const target = document.body;
    // Append element to dom
    target.appendChild(el);
    return () => {
      target.removeChild(el);
    };
  }, [el]);

  return ReactDOM.createPortal(children, el);
}

function Modal({ open, onClose, children }) {
  const [active, setActive] = useState(false);
  // Make a reference to the backdrop
  const backdrop = useRef(null);

  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: open ? 1 : 0,
    transform: open ? `translateY(0%)` : `translateY(-100%)`,
  });

  // the modal is conditionally rendered
  // so to get the closing animation
  // we need to delay removing the modal
  // when open is false
  useEffect(() => {
    let timeOutFunc;
    if (open) {
      setActive(open);
    } else {
      timeOutFunc = setTimeout(() => {
        setActive(open);
      }, 400);
    }

    return () => {
      clearTimeout(timeOutFunc);
    };
  }, [open]);

  useEffect(() => {
    let timeOutFunc;

    const closeModal = () => {
      onClose();
    };
    // get dom element from backdrop
    const { current } = backdrop;
    // when esc key press close modal
    const keyHandler = (e) => [27].indexOf(e.which) >= 0 && closeModal();
    // when clicking the backdrop close modal unless locked
    const clickHandler = (e) => e.target === current && closeModal();

    // if the backdrop exists set up listeners
    if (current) {
      current.addEventListener("click", clickHandler);
      window.addEventListener("keyup", keyHandler);
    }

    // if open props is true add inert to #root
    if (open) {
      timeOutFunc = setTimeout(() => {
        document.activeElement.blur();
        document.querySelector("#root").setAttribute("inert", "true");
      }, 10);
    }

    return () => {
      if (current) {
        current.removeEventListener("click", clickHandler);
      }

      document.querySelector("#root").removeAttribute("inert");
      window.removeEventListener("keyup", keyHandler);

      clearTimeout(timeOutFunc);
    };
  }, [open, onClose]);

  return (
    <>
      {active && (
        <PortalContainer className="modal-portal">
          <Backdrop ref={backdrop}>
            <animated.div style={animation}>
              <Content className="modal-content">{children}</Content>
            </animated.div>
          </Backdrop>
        </PortalContainer>
      )}
    </>
  );
}

export default Modal;
