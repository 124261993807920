import styled from "styled-components";

const Mobile = styled.div`
  display: initial;
  margin: 0;
  padding: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    display: none;
  }
`;

const Tablet = styled.div`
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    display: initial;
    margin: 0;
    padding: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    display: none;
  }
`;

const Desktop = styled.div`
  display: none;
  margin: 0;
  padding: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    display: initial;
  }
`;

export const MobileOnly = ({ children }) => <Mobile>{children}</Mobile>;
export const TabletOnly = ({ children }) => <Tablet>{children}</Tablet>;
export const DesktopOnly = ({ children }) => <Desktop>{children}</Desktop>;
