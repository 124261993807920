import React from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import header from "../assets/small-header.png";
import footer from "../assets/footer.png";
import flag from "../assets/flag.png";

const maxWidth = "1234px";
const orange = "#FE5000";
const blue = "#26C3EA";
const gray = "#A8A9AC";
const tabGray = "#F4F4F4";

const primaryButton = `
    color: #fff;
    background-color: ${blue};
    font-family: var(--font-primary);
    font-size: 18px;
    line-height: 10px;
    font-weight: 800;
    height: 30px;
    border: 1px solid ${blue};
    max-width: 200px;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      background-color: ${gray};
      border: 1px solid ${gray};
    }
  `;

const secondaryButton = `
    color: ${gray};
    background-color: #fff;
    font-family: var(--font-primary);
    font-size: 18px;
    line-height: 18px;
    font-weight: 800;
    height: 30px;
    border: 1px solid ${gray};
    max-width: 200px;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      background-color: ${gray};
      border: 1px solid ${gray};
      color: #fff;
    }
  `;

const signupFlowTab = `
    color: #000;
    background-color: ${tabGray};
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-primary);
    font-size: 20px;
    line-height: 24px;
    font-weight: 800;
    height: 35px;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    margin-top: 8px;
    margin-bottom: 8px;
    cursor: default;

    &.active {
      background-color: ${orange};
      color: #fff;
    }

    @media (min-width: 1024px) {
      margin: 0
    }
  `;

const theme = {
  colors: {
    orange,
    blue,
    gray,
    lightGray: "rgba(196, 196, 196, 0.1)",
    tabGray,
    spinnerOrangePrimary: "rgba(254, 80, 0, 0.5)",
    spinnerOrangeSecondary: "rgba(254, 80, 0, 0.8)",
  },
  images: {
    header,
    footer,
    flag,
  },
  dimensions: {
    maxWidth,
  },
  buttons: {
    primaryButton,
    secondaryButton,
    signupFlowTab,
  },
  breakpoints: {
    mobile: "480px",
    small: "768px",
    medium: "1024px",
    large: "1201px",
  },
  modal: {
    mobile: {
      minWidth: "320px",
      minHeight: "320px",
    },
    small: {
      minWidth: "360px",
      minHeight: "320px",
    },
  },
};

const GlobalStyle = createGlobalStyle`
// Corporate S Pro
  @font-face {
    font-family:"corporate-s";
    src:url("https://use.typekit.net/af/9b7203/0000000000000000000178d1/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/9b7203/0000000000000000000178d1/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/9b7203/0000000000000000000178d1/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;
  }

  @font-face {
    font-family:"corporate-s";
    src:url("https://use.typekit.net/af/421540/0000000000000000000178d5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/421540/0000000000000000000178d5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/421540/0000000000000000000178d5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:600;
  }

  @font-face {
    font-family:"corporate-s";
    src:url("https://use.typekit.net/af/50bd6b/0000000000000000000178d7/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/50bd6b/0000000000000000000178d7/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/50bd6b/0000000000000000000178d7/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;
  }

  // Oswald Semibold
 @font-face {
    font-family: 'oswald semibold';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvsUtiYySUhiCXABTV.woff) format('woff');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  @font-face {
    font-family: 'oswald semibold';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvsUJiYySUhiCXABTV.woff) format('woff');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  @font-face {
    font-family: 'oswald semibold';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvsUliYySUhiCXABTV.woff) format('woff');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }

  @font-face {
    font-family: 'oswald semibold';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvsUhiYySUhiCXABTV.woff) format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  @font-face {
    font-family: 'oswald semibold';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvsUZiYySUhiCXAA.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  // Oswald
   @font-face {
    font-family: 'oswald';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvsUtiYySUhiCXABTV.woff) format('woff');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  @font-face {
    font-family: 'oswald';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvsUJiYySUhiCXABTV.woff) format('woff');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  @font-face {
    font-family: 'oswald';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvsUliYySUhiCXABTV.woff) format('woff');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }

  @font-face {
    font-family: 'oswald';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvsUhiYySUhiCXABTV.woff) format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  @font-face {
    font-family: 'oswald';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvsUZiYySUhiCXAA.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  :root {
    --font-primary: "corporate-s";
    --font-secondary: "oswald semibold";
    --font-secondary-thin: "oswald";
  }

  a {
    color: ${(props) => props.theme.colors.blue};
    font-family: var(--font-primary);
    font-size: 18px;
    font-weight: 800;
    text-decoration: none;
  }
`;

export default function Theme({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
}
