import { useCallback } from "react";
import { useAuth, checkLoggedIn } from "../store/auth";
import { checkStatus } from "../utils";

const baseApiUrl = process.env["REACT_APP_API_URL"] || "";

export default function useApi() {
  const { state } = useAuth();

  const getAccessToken = useCallback(async () => {
    if (checkLoggedIn(state)) {
      return state.id_token;
    } else {
      // If the token is expired, redirect the user to
      // the login page.
      if (window.location.pathname !== "/login") {
        window.location = "/login";
      }
    }
  }, [state]);

  const authenticatedFetch = useCallback(
    async (endpoint, init = {}) => {
      const accessToken = await getAccessToken();
      const url = `${baseApiUrl}/${endpoint}`;
      const res = await fetch(url, {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
        ...init,
      });

      // should throw a custom error/error on bad responses
      const checkedResponse = checkStatus(res);

      return checkedResponse;
    },
    [getAccessToken]
  );

  const authenticatedFetchImage = useCallback(
    async (endpoint, init = {}) => {
      const accessToken = await getAccessToken();
      const url = baseApiUrl + endpoint;
      const res = await fetch(url, {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "image/png",
        },
        ...init,
      });
      return res.blob();
    },
    [getAccessToken]
  );

  const fetchGET = useCallback(
    (endpoint) => authenticatedFetch(endpoint),
    [authenticatedFetch]
  );

  const fetchGETImage = useCallback(
    (endpoint) => authenticatedFetchImage(endpoint),
    [authenticatedFetchImage]
  );

  const fetchPOST = useCallback(
    (endpoint, data) =>
      authenticatedFetch(endpoint, {
        method: "POST",
        body: JSON.stringify(data),
      }),
    [authenticatedFetch]
  );

  const fetchPATCH = useCallback(
    (endpoint, data) =>
      authenticatedFetch(endpoint, {
        method: "PATCH",
        body: JSON.stringify(data),
      }),
    [authenticatedFetch]
  );

  return {
    baseApiUrl,
    getAccessToken,
    authenticatedFetch,
    authenticatedFetchImage,
    fetchGET,
    fetchGETImage,
    fetchPOST,
    fetchPATCH,
  };
}
