import styled, { keyframes } from "styled-components";

const clip = keyframes`
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.0);
  }
`;

const LargeSpinnerDiv = styled.div`
  width: 100px;
  height: 100px;
  background-color: transparent;
  display: grid;
  place-items: center;

  div {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.spinnerOrangePrimary};
  }

  div::after {
    content: "";
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.spinnerOrangeSecondary};
    z-index: 1;
    position: absolute;
    animation: ${clip} 1s ease infinite;
  }
`;

const SmallSpinnerDiv = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  div {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.spinnerOrangePrimary};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div::after {
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.spinnerOrangeSecondary};
    z-index: 1;
    position: absolute;
    animation: ${clip} 1s ease infinite;
  }
`;

const Spinner = ({ size }) => {
  return size === "large" ? (
    <LargeSpinnerDiv>
      <div />
    </LargeSpinnerDiv>
  ) : (
    <SmallSpinnerDiv>
      <div />
    </SmallSpinnerDiv>
  );
};

Spinner.defaultProps = {
  size: "large",
};

export default Spinner;
