const ChevronDown = () => {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 2L9 9L16 2" stroke="#26C3EA" strokeWidth="3" />
    </svg>
  );
};

export default ChevronDown;
