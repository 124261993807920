import { useEffect, useState } from "react";
import styled from "styled-components";
import { truncateString } from "utils";
import { useApi } from "../../hooks";

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.theme.modal.mobile.minWidth};
  padding: 20px;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    width: ${(props) => props.theme.modal.small.minWidth};
    height: ${(props) => props.theme.modal.small.minHeight};
  }

  & .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }

  & img {
    height: 100%;
    width: 100%;
    max-width: ${(props) => props.theme.modal.mobile.minWidth};

    @media (min-width: ${(props) => props.theme.breakpoints.small}) {
      max-width: ${(props) => props.theme.modal.small.minWidth};
    }
  }

  & .title {
    font-family: var(--font-secondary);
    font-size: 25px;
    color: ${(props) => props.theme.colors.orange};
  }

  & .description {
    font-family: var(--font-primary);
    font-size: 17px;
  }

  & p {
    text-align: center;
  }
`;

const PrimaryButton = styled.button`
  ${(props) => props.theme.buttons.primaryButton}
`;

const BarcodeRedeem = ({ event, reward, closeModal }) => {
  const { fetchGETImage } = useApi();
  const [imageBlob, setImageBlob] = useState();
  useEffect(() => {
    if (!event) return;
    async function fetchCouponImage() {
      const response = await fetchGETImage(`/events/${event.id}/reward_image`);
      setImageBlob(URL.createObjectURL(response));
    }
    fetchCouponImage();
  }, [fetchGETImage, event]);

  if (!reward) return null;
  if (!event) return null;

  return (
    <Container>
      <div className="content-container">
        <div className="content">
          <p className="title">{truncateString(reward.name, 25)}</p>
          <div className="barcode">
            <img src={imageBlob} alt={`Barcode for ${reward.name} promotion`} />
          </div>
          <p className="description">{reward.description}</p>
        </div>
        <div className="buttons">
          <PrimaryButton
            onClick={() => {
              closeModal();
            }}
          >
            Close
          </PrimaryButton>
        </div>
      </div>
    </Container>
  );
};

export default BarcodeRedeem;
