import { Routes, Route } from "react-router-dom";
import { AnonymousHeader, LoggedInHeader } from "../../components";

import { useAuth, checkLoggedIn } from "../../store/auth";

const Header = () => {
  const { state } = useAuth();
  const loggedIn = checkLoggedIn(state);

  return (
    <>
      {loggedIn ? (
        <LoggedInHeader />
      ) : (
        <Routes>
          <Route path="/login-concept" element={<LoggedInHeader />} />
          <Route path="/signup/*" element={<LoggedInHeader />} />
          <Route path="*" element={<AnonymousHeader />} />
        </Routes>
      )}
    </>
  );
};

export default Header;
