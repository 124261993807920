import React, { useState } from "react";
import styled from "styled-components";
import { Modal, SignupFlow } from "../components";
import { useNavigate, useLocation } from "react-router-dom";

export default function Signup() {
  const navigate = useNavigate();

  const { state: profile } = useLocation();

  const [showModal, setShowModal] = useState(false);

  const PrimaryButton = styled.button`
    max-width: 400px !important;
    ${(props) => props.theme.buttons.primaryButton}
  `;

  const SecondaryButton = styled.button`
    max-width: 400px !important;
    ${(props) => props.theme.buttons.secondaryButton}
  `;

  const ModalContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: ${(props) => props.theme.modal.mobile.minWidth};

    @media (min-width: ${(props) => props.theme.breakpoints.small}) {
      justify-content: flex-start;
      width: ${(props) => props.theme.modal.small.minWidth};
      height: ${(props) => props.theme.modal.small.minHeight};
    }

    .content-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      margin: 31px auto 18px auto;
      height: 100%;
    }

    p {
      margin: 0;
      padding: 0;
    }

    button {
      margin-top: 15px;
      height: 100%;
      line-height: 18px;

      @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
        height: 30px;
      }
    }

    .prompt {
      font-family: var(--font-secondary);
      font-size: 17px;
      text-align: left;
      color: ${(props) => props.theme.colors.orange};
      text-transform: uppercase;
      margin-bottom: 15px;
      max-width: 300px;
    }

    .content {
      font-family: var(--font-primary);
      font-size: 19px;
      line-height: 130%;
    }

    .bold {
      font-weight: 700;
    }

    .cancel {
      margin-right: 15px;
    }
  `;

  return (
    <>
      <SignupFlow setShowModal={setShowModal} />
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <ModalContainer>
          <div className="content-container">
            <p className="prompt">
              Are you sure you want to Skip connecting your account?
            </p>
            <p className="content bold">
              If you skip you won’t be able to earn rewards for using the wash.
            </p>
            <p className="content">
              But we’ll still give you a chance to link your account later.
              After you log in you’ll see a banner at the top of the website
              that has instructions to contact the car wash.
            </p>
            <div className="buttons">
              <SecondaryButton onClick={() => setShowModal(false)}>
                Cancel - I want to connect my account
              </SecondaryButton>

              <PrimaryButton
                onClick={() => {
                  setShowModal(false);
                  navigate("/signup/link", {
                    state: { ...profile, skipped: true },
                  });
                }}
              >
                Don't connect my account
              </PrimaryButton>
            </div>
          </div>
        </ModalContainer>
      </Modal>
    </>
  );
}
