const ProfileIcon = () => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 14H10.7966C10.7408 12.2172 10.0514 10.7872 8.66103 9.77677C7.82254 9.16765 6.87652 8.89255 5.86081 8.93157C4.5776 8.98014 3.4761 9.48893 2.57954 10.4695C1.68297 11.4501 1.25474 12.6349 1.22626 13.9964H0C0.130756 11.2801 1.34878 9.35357 3.63233 8.15564C2.27531 7.04887 1.62865 5.60768 1.86656 3.7863C2.01305 2.66201 2.52708 1.72923 3.35508 1.0154C5.13472 -0.518148 7.52506 -0.229911 8.97499 1.3116C9.56278 1.92757 9.96208 2.7176 10.1207 3.57848C10.2899 4.43354 10.2129 5.32292 9.89965 6.13081C9.58119 6.9569 9.0724 7.62375 8.36167 8.15683C9.47067 8.68235 10.3522 9.45907 11.0075 10.5085C11.6628 11.5579 11.9936 12.7212 12 14ZM8.9941 4.48698C8.99897 2.73527 7.71089 1.32952 6.09123 1.29448C4.3884 1.25706 3.02239 2.66361 3.02426 4.45235C3.02651 6.23353 4.32995 7.57518 5.90427 7.63012C7.63296 7.69024 8.97686 6.24666 8.9941 4.48698Z"
        fill="white"
      />
    </svg>
  );
};

export default ProfileIcon;
